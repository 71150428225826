import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Math Module`}</h1>
    <p>{`The part of the `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` system which performs the real-time `}<a parentName="p" {...{
        "href": "/machine-learning",
        "title": "machine learning"
      }}>{`machine learning`}</a>{`.`}</p>
    <p>{`See the `}<inlineCode parentName="p">{`math`}</inlineCode>{` directory here: `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis/"
      }}>{`https://github.com/compdemocracy/polis/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      